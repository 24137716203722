import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Serendipity`}</h1>
    <p>{`Folks often refer to serendipity as a "happy accident". However,
when designing, it's difficult to have one. When you focus on each
pixel it becomes time consuming to try out different variations of
a component.`}</p>
    <p>{`When something is time consuming, you try less variations. `}<inlineCode parentName="p">{`compai`}</inlineCode>{`
is `}<strong parentName="p">{`built with serendipity in mind`}</strong>{`. Through component generation you
can `}<strong parentName="p">{`see hundreds of component variations`}</strong>{` in the same amount of
time it typically takes you to design one. When you're able to cycle
through hundreds or even thousands of variations you'll see designs
that you'd never think of. Sometimes that's a good thing.`}</p>
    <p>{`If desired, `}<inlineCode parentName="p">{`compai`}</inlineCode>{` can even pull in new design tokens to try out
completely new component variants. The possibilities are endless.
`}<strong parentName="p">{`And it's fun`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      